import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Page = () => (
  <Layout>
    <Seo title="Hvilke plattformer støttes?" />
    <div className="longtext">
      <h2>Hvilke plattformer støttes?</h2>
      <p>Du kan bruke appen på iPhone, iPad og macOS. </p>

      <p>
        Støtte for Android, web og Windows er dessverre langt frem i tid, men om
        ting går bra håper vi å kunne ansette flere for å utvide til flere
        plattformer i fremtiden!
      </p>
    </div>
  </Layout>
)

export default Page
